import React, { useEffect, useState } from "react";
import ReferEarnImg from "../assets/svg/refer_and_earn.svg";
import facebookLogo from "../assets/images/facebook.png";
import whatsappLogo from "../assets/images/whatsapp.jpg";
import twitterLogo from "../assets/images/tweeter_image.png";
import linkedinLogo from "../assets/images/linkdin.png";
import CopyImg from "../assets/svg/copy_icon.svg";
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton } from "react-share";
import { useSelector } from "react-redux";

export default function ReferEarn({ referCode, referClick }) {
  const [copied, setCopied] = useState(false);
  const [inviteClick, setInviteClick] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const user = useSelector((state) => state.userInfo.userDetails)
  // const referralId = user.data.referralId;
  
  const ShareOptions = ({ shareUrl = "https://development.alltails.in" }) => {
    const facebookMessage = `Check out this amazing link and use this coupon code: ${referCode}`;
    const twitterMessage = `Check out this amazing link and use this coupon code: ${referCode}`;
    const linkedinMessage = `Explore this link and use this coupon code: ${referCode}`;
    const whatsappMessage = `Hey, check this out! Use my referral code: ${referCode}`;

    return (
      <div className="share-options" style={{ display: "flex", flex: "wrap", marginTop: "2vh" }}>
        <FacebookShareButton url={shareUrl} quote={facebookMessage}>
          <img style={{ width: "4vh" }} src={facebookLogo} alt="Share on Facebook" />
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl} title={twitterMessage}>
          <img style={{ width: "4vh" }} src={twitterLogo} alt="Share on Twitter" />
        </TwitterShareButton>
        <LinkedinShareButton url={shareUrl} title={linkedinMessage}>
          <img style={{ width: "4vh" }} src={linkedinLogo} alt="Share on LinkedIn" />
        </LinkedinShareButton>
        <WhatsappShareButton url={shareUrl} title={whatsappMessage}>
          <img style={{ width: "4vh" }} src={whatsappLogo} alt="Share on WhatsApp" />
        </WhatsappShareButton>
      </div>
    );
  }; 
  
  const InvitePopup = ({ onClose }) => (
    <div className="popup">
      <button onClick={onClose}>Close</button>
      <ShareOptions shareUrl="https://development.alltail.in" />
    </div>
  );

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
      setInviteClick(false);
    }, [3000]);
  }, [copied, inviteClick]);

  const handleInviteClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  return (
    <section className="refer_earn">
      <div className="container">
        <div className="refer_earn_wrapper">
          <div className="row">
            <div className="col-md-12 col-xl-12">
              <div className="main_img">
                <img src={ReferEarnImg} alt="refer and earn" />
              </div>
            </div>
            <div className="col-md-12 col-xl-12">
              <div className="refer_text">
                <div className="title">Refer & Earn</div>
                <div className="content">"Refer a friend and get a 10% discount your next bookings”</div>
              </div>
            </div>
            <div className="col-md-12 col-xl-12">
              <div className="code_container">
                <div className="code_wrapper">
                  <div className="code">
                    <div className="code_content">
                      <div className="code_text">{referCode}</div>  
                      <div className="code_copy">{copied ? "Copied" : "Tap to copy"}</div>
                    </div>
                    <div className="icon">
                      <button
                        onClick={() => {
                          navigator.clipboard.writeText(referCode);
                          // referClick(referCode);
                          setCopied(true);
                        }}
                      >
                        <img src={CopyImg} alt="refer and earn" />
                      </button>
                    </div>
                  </div>
                  <div className="action">
                    {/* {<button  onClick={InvitePopup}>Invite</button>} */}
                    {/* {inviteClick && <ShareOptions shareUrl="https://development.alltail.in" />} */}
                    {!showPopup && <button onClick={handleInviteClick}>Invite</button>}
                    {showPopup && <InvitePopup onClose={handleClosePopup} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
