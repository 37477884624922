
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const SHOW_REACTIVATE_USER_POPUP = "SHOW_REACTIVATE_USER_POPUP";
export const CLOSE_REACTIVATE_USER_POPUP = "CLOSE_REACTIVATE_USER_POPUPCLOSE"


export const showReactivateAccountPopup = () => {
  return (dispatch) => {
    dispatch({ type: SHOW_REACTIVATE_USER_POPUP })
  }
}

export const closeReactivateAccountPopup = () => {
  return (dispatch) => {
    dispatch({ type: CLOSE_REACTIVATE_USER_POPUP })
  }
}

