import * as userReactivateAction from "./userReactivateAction";


export const reactivateAccountFeatureKey = "reactivateUserAccountInfo";


export const initialState = {
    showReactivateAccountPopup: false,
}

export const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case userReactivateAction.SHOW_REACTIVATE_USER_POPUP:
            return {
                showReactivateAccountPopup: true
            }
        case userReactivateAction.CLOSE_REACTIVATE_USER_POPUP:
            return {
                showReactivateAccountPopup: false
            }
        default:
            return state;
    }
};