import { useDispatch } from "react-redux";
import * as userActions from "./userAction";

export const userFeatureKey = "userInfo";

const initialState = {
  loading: false,
  loadingForLogin: false,
  loadingForRegister: false,
  isUserUpdate: false,
  eOtpLoading:false,
  errorMsg: "",
  loggedUser: {},
  users: [],
  counts: {},
  userDetails: {},
  devices: [],
  addressDetails: {},
  notificationData: {},
  notificationHistoryData: [],
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case userActions.RECEIVED_PUSH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationData: payload,
      };

    case userActions.RECEIVED_PUSH_NOTIFICATION_CLEAR:
      return {
        ...state,
        notificationData: {},
      };

    case userActions.STORE_MARKETING_FCM_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case userActions.STORE_MARKETING_FCM_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case userActions.STORE_MARKETING_FCM_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // login user by google
    case userActions.GOOGLE_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        loadingForLogin: true,
      };

    case userActions.GOOGLE_LOGIN_SUCCESS:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        loading: false,
        loadingForLogin: false,
        loggedUser: payload.data,
      };

    case userActions.GOOGLE_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        loadingForLogin: false,
        errorMsg: payload,
      };
    
      case userActions.FACEBOOK_LOGIN_REQUEST:
        return {
          ...state,
          loading: true,
          loadingForLogin: true,
        };
  
      case userActions.FACEBOOK_LOGIN_SUCCESS:
        localStorage.setItem("token", payload.token);
        return {
          ...state,
          loading: false,
          loadingForLogin: false,
          loggedUser: payload.data,
        };
  
      case userActions.FACEBOOK_LOGIN_FAILURE:
        return {
          ...state,
          loading: false,
          loadingForLogin: false,
          errorMsg: payload,
        };

    // Logout user:
    case userActions.LOGOUT_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case userActions.LOGOUT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case userActions.LOGOUT_USER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // mobile number login
    case userActions.MOBILE_NUMBER_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        loadingForLogin: true,
      };

    case userActions.MOBILE_NUMBER_LOGIN_SUCCESS:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        loading: false,
        loadingForLogin: false,
        loggedUser: payload.data,
      };

    case userActions.MOBILE_NUMBER_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        loadingForLogin: false,
        errorMsg: payload,
      };

    // get user
    case userActions.GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case userActions.GET_USER_SUCCESS:
      let data;
      return {
        ...state,
        loading: false,
        userDetails: payload,
        addressDetails: data || {},
      };

    case userActions.GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // update user
    case userActions.UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        isUserUpdate: false,
      };

    case userActions.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isUserUpdate: true,
      };

    case userActions.UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case userActions.DELETE_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case userActions.DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        loggedUser : {}
      };

    case userActions.DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // send Email Otp
    case userActions.SEND_EMAIL_OTP_REQUEST:
      return {
        ...state,
        eOtpLoading: true,
      };

    case userActions.SEND_EMAIL_OTP_SUCCESS:
      return {
        ...state,
        eOtpLoading: false,
      };

    case userActions.SEND_EMAIL_OTP_FAILURE:
      return {
        ...state,
        eOtpLoading: false,
        errorMsg: payload,
      };

    // Verify email otp:
    case userActions.VERIFY_EMAIL_OTP_REQUEST:
      return {
        ...state,
        // loading: true,
      };

    case userActions.VERIFY_EMAIL_OTP_SUCCESS:
      return {
        ...state,
        // loading: false,
      };

    case userActions.VERIFY_EMAIL_OTP_FAILURE:
      return {
        ...state,
        // loading: false,
        errorMsg: payload,
      };

    case userActions.GET_USER_NITIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case userActions.GET_USER_NITIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationHistoryData: payload.data,
        notificationHistoryDataCount: payload.total,
      };

    case userActions.GET_USER_NITIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case userActions.UPDATE_USER_NITIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case userActions.UPDATE_USER_NITIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case userActions.UPDATE_USER_NITIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case userActions.REACTIVATE_USER_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case userActions.REACTIVATE_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case userActions.REACTIVATE_USER_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
      
    default:
      return state;
  }
};
