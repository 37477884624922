import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  getRedirectResult,
  signInWithRedirect,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  FacebookAuthProvider,
} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { toast } from "react-toastify";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_API_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_API_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_API_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_API_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_API_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_API_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();
export const messaging = getMessaging(app);
export const auth = getAuth();

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     console.log("hit")
//     onMessage(messaging, (payload) => {
//       resolve(payload);
//     });
//   });

  export const onMessageListener = (callback) => {
    onMessage(messaging, (payload) => {
      // console.log("Foreground message received:", payload);
      callback(payload);
    });
  };

  // // / Register the service worker and wait for it to become active
  // navigator.serviceWorker.register('/firebase-messaging-sw.js')
  //   .then((registration) => {
  //     console.log('Service Worker registered with scope:', registration.scope);
  
  //     // Ensure the service worker is active
  //     return navigator.serviceWorker.ready;
  //   })
  //   .then((registration) => {
  //     console.log('Service Worker is ready:', registration);
  
  //     // Request permission and get token
  //     return getToken(messaging, { vapidKey: 'YOUR_PUBLIC_VAPID_KEY' });
  //   })
  //   .then((currentToken) => {
  //     if (currentToken) {
  //       console.log('FCM Token:', currentToken);
  //       // Send the token to your server and update the UI if necessary
  //     } else {
  //       console.log('No registration token available. Request permission to generate one.');
  //     }
  //   })
  //   .catch((err) => {
  //     console.log('An error occurred while retrieving token. ', err);
  //   });

export const getTokenNotification = async () => {
  // console.log(firebaseConfig)
  return getToken(messaging, { vapidKey: process.env.REACT_APP_API_FIREBASE_VAPID_KEY })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
      }
    })
    .catch((err) => {
      // console.log("An error occurred while retrieving token. ", err);
    });
};

export const loginGooglePopup = async (dispatch, navigate, data, googleLogin) => {
  try {
    await socialLogout();
    await signInWithPopup(auth, provider);
    const idToken = await auth.currentUser.getIdToken();
    // // login user
    dispatch(googleLogin({ socialLoginToken: idToken, socialLoginType: "G", ...data }, navigate));
  } catch (error) {
    console.error("firebase error", error);
  }
};

export const socialLogout = async () => {
  try {
    const user = await auth.currentUser;
    if (user) {
      await auth.signOut();
    }
    // console.log("signedout");
  } catch (error) {
    console.error("firebase error", error);
  }
};

export const getFirebaseUser = async () => {
  try {
    const user = await auth.currentUser;
    return user.phoneNumber;
  } catch (error) {
    // console.error("error");
  }
};

export const getFirebaseUserEmail = async () => {
  try {
    await signInWithPopup(auth, provider);
    const userEmail = auth.currentUser.email;
    return userEmail;
  } catch (error) {
    
  }
}

export const sentPhoneOtp = async (phoneNumber) => {
  try {
    if (window.appVerifier) {
      window.appVerifier.clear();  
      window.appVerifier = null;
    } 
    const recaptchaContainer = document.getElementById("recaptcha-container");
    window.appVerifier = new RecaptchaVerifier(auth, recaptchaContainer, {
      size: "invisible",
    });
    const confirmation = await signInWithPhoneNumber(auth, phoneNumber, window.appVerifier);
    window.confirmation = confirmation;
    // console.log("otp send successfully");
  } catch (error) {
    // console.error("Phone OTP error", error);
  }
};

// Resend phone OTP
export const resendPhoneOtp = async (phoneNumber) => {
  try {
    // const recaptchaContainer = document.getElementById("recaptcha-container"); // Use the same container ID as before
    const confirmation = await signInWithPhoneNumber(
      auth,
      phoneNumber,
      window.appVerifier // Reuse the existing RecaptchaVerifier instance
    );
    window.confirmation = confirmation;
  } catch (error) {
    console.error("Phone OTP error", error);
  }
};

export const handleOtpVerify = async (otpData, navigate, dispatch, data, mobileNumberLogin) => {
  try {
    const confirmation = window.confirmation;
    await confirmation.confirm(otpData);
    const idToken = await auth.currentUser.getIdToken();
    dispatch(mobileNumberLogin({ socialLoginToken: idToken, socialLoginType: "P", ...data }, navigate));
    return idToken;
  } catch (error) {
    toast.error("Invalid OTP");
    // console.error("Error during OTP verification", error);
    return false;
  }
};

export const handleOnlyNumberVerify = async (otpData) => {
  try {
    const confirmation = window.confirmation;
    await confirmation.confirm(otpData);
    const idToken = await auth.currentUser.getIdToken();
    return idToken;
  } catch (error) {
    toast.error("Invalid OTP");
    // console.error("Error during OTP verification", error);
    return false;
  }
};

export const loginFacebookPopup = async (dispatch, navigate, data, facebookLogin) => {
  try {
    await socialLogout();
    const fbProvider = new FacebookAuthProvider();
    const result = await signInWithPopup(auth, fbProvider)

    const idToken = await auth.currentUser.getIdToken();
    // // login user
    dispatch(facebookLogin({ socialLoginToken: idToken, socialLoginType: "F", ...data }, navigate));
  } catch (error) {
    
  }
}