import * as ambulanceActions from "./ambulanceAction";
export const ambulanceFeatureKey = "ambulanceInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  allAmbulance: [],
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // get Schedule Vets List
    case ambulanceActions.GET_AMBULANCE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ambulanceActions.GET_AMBULANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        allAmbulance: payload.data,
        // count: payload?.count,
      };
    case ambulanceActions.GET_AMBULANCE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    default:
      return state;
  }
};
