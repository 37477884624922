import * as deleteAccountAction from "./deleteAccountAction";


export const deleteAccountFeatureKey = "deletAccountInfo";


export const initialState = {
    showDeletePopup: false,
}

export const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
            
        case deleteAccountAction.TOGGLE_DELETE_POPUP:
            return {
                ...state,
                showDeletePopup: !state.showDeletePopup
            }
        default:
            return state;
    }
};