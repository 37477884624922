import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const TOGGLE_DELETE_POPUP = "TOGGLE_DELETE_POPUP";


export const showDeletePopup = () => {
return (dispatch) => {
  dispatch({ type: TOGGLE_DELETE_POPUP})
}
}

