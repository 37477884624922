import * as cartAction from "./cartAction";
export const cartFeatureKey = "cartInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  addCart: {},
  myCart: [],
  billingDetails: {},
  billingLoading: false
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // get Cart
    case cartAction.GET_MY_CART_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case cartAction.GET_MY_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        myCart: payload,
      };

    case cartAction.GET_MY_CART_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Add to Cart
    case cartAction.ADD_TO_CART_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case cartAction.ADD_TO_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        addCart: payload,
      };

    case cartAction.ADD_TO_CART_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Add all to cart:
    case cartAction.ADD_ALL_TO_CART_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case cartAction.ADD_ALL_TO_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        // addCart: payload,
      };

    case cartAction.ADD_ALL_TO_CART_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Update Cart Item
    case cartAction.UPDATE_CART_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case cartAction.UPDATE_CART_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case cartAction.UPDATE_CART_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Delete cart item:
    case cartAction.REMOVE_FROM_CART_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case cartAction.REMOVE_FROM_CART_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case cartAction.REMOVE_FROM_CART_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Delete cart item:
    case cartAction.GET_CART_BILLING_DETAILS_REQUEST:
      return {
        ...state,
        billingLoading: true,
      };

    case cartAction.GET_CART_BILLING_DETAILS_SUCCESS:
      return {
        ...state,
        billingLoading: false,
        billingDetails: payload.data
      };

    case cartAction.GET_CART_BILLING_DETAILS_FAILURE:
      return {
        ...state,
        billingLoading: false,
        errorMsg: payload,
      };

    default:
      return state;
  }
};
