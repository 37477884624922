import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { showDeletePopup } from '../../redux/delete_account/deleteAccountAction';
import { deleteUserAccount, getUser } from '../../redux/user/userAction';

const DeleteAccountPopup = () => {

    const [ isDeleted, setIsDeleted ] = useState(false);
    const user = useSelector((state) => state.userInfo.userDetails.data)
    const showPopup = useSelector((state) => state.deletAccountInfo.showDeletePopup)
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const handleDeleteUser = () => {
      dispatch(deleteUserAccount())
      dispatch(showDeletePopup())
      localStorage.removeItem("token");
      localStorage.removeItem("location");
      setIsDeleted(true);
      window.location.reload()
      navigate("/login");
    }

  return (
    <Modal show={showPopup} onHide={() => dispatch(showDeletePopup())} centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-danger">Confirm Account Deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete your account?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => dispatch(showDeletePopup())}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => handleDeleteUser()} >
          Delete My Account
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteAccountPopup;
