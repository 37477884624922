import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// Add Order
export const ADD_ORDER_REQUEST = "ADD_ORDER_REQUEST";
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS";
export const ADD_ORDER_FAILURE = "ADD_ORDER_FAILURE";

// Get Orders
export const GET_SINGLE_ORDER_REQUEST = "GET_SINGLE_ORDER_REQUEST";
export const GET_SINGLE_ORDER_SUCCESS = "GET_SINGLE_ORDER_SUCCESS";
export const GET_SINGLE_ORDER_FAILURE = "GET_SINGLE_ORDER_FAILURE";

// get All Order
export const GET_ALL_ORDER_REQUEST = "GET_ALL_ORDER_REQUEST";
export const GET_ALL_ORDER_SUCCESS = "GET_ALL_ORDER_SUCCESS";
export const GET_ALL_ORDER_FAILURE = "GET_ALL_ORDER_FAILURE";

// Get All Order Cancel Reasons
export const GET_ALL_CANCEL_REASONS_REQUEST = "GET_ALL_CANCEL_REASONS_REQUEST";
export const GET_ALL_CANCEL_REASONS_SUCCESS = "GET_ALL_CANCEL_REASONS_SUCCESS";
export const GET_ALL_CANCEL_REASONS_FAILURE = "GET_ALL_CANCEL_REASONS_FAILURE";

// Get discount and delivery charges
export const GET_DISCOUNT_AND_DELIVERY_CHARGES_REQUEST = "GET_DISCOUNT_AND_DELIVERY_CHARGES_REQUEST";
export const GET_DISCOUNT_AND_DELIVERY_CHARGES_SUCCESS = "GET_DISCOUNT_AND_DELIVERY_CHARGES_SUCCESS";
export const GET_DISCOUNT_AND_DELIVERY_CHARGES_FAILURE = "GET_DISCOUNT_AND_DELIVERY_CHARGES_FAILURE";

// Update Order
export const UPDATE_ORDER_REQUEST = "UPDATE_ORDER_REQUEST";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAILURE = "UPDATE_ORDER_FAILURE";

export const addOrder = (data, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_ORDER_REQUEST });

      if (data?.selectedFile) {
        const url = `${BASE_URL}/order/upload/order/prescription/image`;
        const response = await axios.post(
          url,
          { sFileName: data?.selectedFile?.name, sContentType: data?.selectedFile?.type },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );
        const { sUrl, sPath } = response.data.data;
        data.attachPrescriptionFile = sPath;

        await axios.put(sUrl, data?.selectedFile, {
          headers: {
            "Content-Type": data?.selectedFile.type,
          },
        });
      }

      const url = `${BASE_URL}/order/add/order/v2`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: ADD_ORDER_SUCCESS, payload: response?.data });
      if (response?.data) {
        navigate(`/my-orders`);
      }
      toast.success("Order added successfully");
    } catch (error) {
      console.error(error);
      dispatch({ type: ADD_ORDER_FAILURE, payload: error.message });
    }
  };
};

export const getSingleOrder = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SINGLE_ORDER_REQUEST });
      const url = `${BASE_URL}/order/get/single/order/${id}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_SINGLE_ORDER_SUCCESS, payload: response?.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_SINGLE_ORDER_FAILURE, payload: error.message });
    }
  };
};

export const cancelOrderModel = (id, cancellData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_ORDER_REQUEST });
      const url = `${BASE_URL}/order/update/order/${id}/v1`;
      const response = await axios.put(url, cancellData, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      toast.success("Order Cancelled.");
      dispatch({ type: UPDATE_ORDER_SUCCESS, payload: response?.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: UPDATE_ORDER_FAILURE, payload: error.message });
    }
  };
};

export const getAllOrder = (page) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_ORDER_REQUEST });
      const url = `${BASE_URL}/order/get/all/order/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: {
          page: page,
        },
      });
      dispatch({ type: GET_ALL_ORDER_SUCCESS, payload: response?.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_ALL_ORDER_FAILURE, payload: error.message });
    }
  };
};

export const getAllCancellResons = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_CANCEL_REASONS_REQUEST });
      const url = `${BASE_URL}/order/get/all/order/dropdown/name/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // alert(JSON.stringify(response))
      dispatch({ type: GET_ALL_CANCEL_REASONS_SUCCESS, payload: response?.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_ALL_CANCEL_REASONS_FAILURE, payload: error.message });
    }
  };
};

export const getDiscountAndDeliveryCharges = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_DISCOUNT_AND_DELIVERY_CHARGES_REQUEST });
      const url = `${BASE_URL}/discount/get/discount/and/delivery/charges/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_DISCOUNT_AND_DELIVERY_CHARGES_SUCCESS, payload: response?.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_DISCOUNT_AND_DELIVERY_CHARGES_FAILURE, payload: error.message });
    }
  };
};
