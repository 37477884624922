import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_AMBULANCE_REQUEST = "GET_AMBULANCE_REQUEST";
export const GET_AMBULANCE_SUCCESS = "GET_AMBULANCE_SUCCESS";
export const GET_AMBULANCE_FAILURE = "GET_AMBULANCE_FAILURE";


export const getAmbulanceList = (data, page) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_AMBULANCE_REQUEST });
      
      const url = `${BASE_URL}/ambulance/list/v1`;

      const response = await axios.get(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: {
          page: page,
        },
      });

      console.log('data', response.data)

      dispatch({ type: GET_AMBULANCE_SUCCESS, payload: response?.data });

    } catch (error) {
      console.error(error);
      dispatch({ type: GET_AMBULANCE_FAILURE, payload: error.message });
    }
  };
};
