import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { closeReactivateAccountPopup } from '../../redux/reactivate_user_popup/userReactivateAction';


const ReactivateAccountPopup = ({emailOrMobileNo, handleReactivate}) => {

    
    const showReactivatePopup = useSelector((state) => state.reactivateUserAccountInfo.showReactivateAccountPopup)
    const dispatch = useDispatch();
    return (
        <Modal show={showReactivatePopup} onHide={() => dispatch(closeReactivateAccountPopup())} centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Reactivate Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to reactivate your account? This will restore full access to all your features.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => dispatch(closeReactivateAccountPopup())}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleReactivate}>
            Reactivate Account
          </Button>
        </Modal.Footer>
      </Modal>
    );
};

export default ReactivateAccountPopup;
